<template>
  <div v-show="getStatsLoaded" class="full-width">
    <app-bar-notification-settings
      v-if="tab === availableTabs.notifications.settings"
    />
    <app-bar-notification-templates
      v-if="tab === availableTabs.notifications.templates"
    />
    <app-bar-newsletter v-if="tab === availableTabs.notifications.newsletter" />
    <app-bar-notification-activity
      v-if="tab === availableTabs.notifications.activity"
    />
  </div>
</template>

<script>
import AppBarBookings from "@/views/dashboard/pages/Sales/AppBar/AppBarBookings.vue";
import sharedAppBarActions from "@/calendesk/mixins/sharedAppBarActions";
import sharedTabsActions from "@/calendesk/mixins/sharedTabsActions";
import AppBarProductTransactions from "@/views/dashboard/pages/Sales/AppBar/AppBarProductTransactions.vue";
import AppBarUserSubscriptions from "@/views/dashboard/pages/Sales/AppBar/AppBarUserSubscriptions.vue";
import AppBarNotificationSettings from "@/views/dashboard/pages/Notifications/AppBar/AppBarNotificationSettings.vue";
import AppBarNotificationTemplates from "@/views/dashboard/pages/Notifications/AppBar/AppBarNotificationTemplates.vue";
import AppBarNewsletter from "@/views/dashboard/pages/Notifications/AppBar/AppBarNewsletter.vue";
import AppBarNotificationActivity from "@/views/dashboard/pages/Notifications/AppBar/AppBarNotificationActivity.vue";

export default {
  components: {
    AppBarNotificationActivity,
    AppBarNewsletter,
    AppBarNotificationTemplates,
    AppBarNotificationSettings,
  },
  mixins: [sharedAppBarActions, sharedTabsActions],
};
</script>
